.main-content {
  position: relative;

  // Navbar
  .navbar-top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  // Container
  .container-fluid {
    /*@include media-breakpoint-up(md) {
            padding-left: ($main-content-padding-x + $grid-gutter-width / 2) !important;
            padding-right: ($main-content-padding-x + $grid-gutter-width / 2) !important;
        }*/
  }
}

// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {
  @each $breakpoint, $dimension in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {
        // Left
        &.fixed-left + .main-content {
          margin-left: $navbar-vertical-width;
        } // Right
        &.fixed-right + .main-content {
          margin-right: $navbar-vertical-width;
        }
      }
    }
  }
}

.search-result-row {
  transition: all 0.1s ease-in-out;

  background-color: $white;

  &:hover {
    background-color: $superlight;
  }
}

@include media-breakpoint-down(sm) {
  .stuck {
    position: sticky;
    top: 0px !important;

    .card {
      height: 80px;
      padding-top: 10px;
      width: calc(100% + 30px);
    }
  }

  .stickable .card {
    transition: all 0s ease-in !important;
  }

  .ios-padding {
    .stuck {
      .card {
        padding-top: 38px;
        height: 111px;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .ios-padding {
    .stuck {
      top: 110px !important;
    }
  }
}

@include media-breakpoint-up(md) {
  .stuck {
    max-width: calc(100% - 135px);
  }
}

.stickable .card {
  transition: all 0.05s ease-in;
}

.stickable {
  &.stuck {
    z-index: 1030;
  }
}

.stuck {
  .card {
    margin-left: -15px;
    margin-right: -150px;
    border-radius: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.modal-open {
  .rs-picker-menu {
    z-index: 999999;
  }
}

.rs-picker {
  .rs-btn {
    background: $white;
    border: 1px solid $lighter;
    transition: 0.2s ease-in border-color;
    outline: 0;

    &:hover {
      background: $white;
      border-color: $primary;
    }
  }
}

.rs-table-scrollbar-horizontal {
  opacity: 0.1;
}

.rs-table-scrollbar-horizontal:hover,
.rs-table-scrollbar-pressed.rs-table-scrollbar-horizontal:hover {
  opacity: 0.7;
}

.main-content-padding {
  margin-top: 6rem;
}

.sidenav-topmargin {
  margin-top: 79px;
}

.navbar-search-container {
  position: fixed;
  top: 81px;
  left: 0;
  z-index: 999999;
  width: 100vw;
  height: calc(100vh - 70px);
  background: rgba(0, 0, 0, 0.2);
}

.ios-padding {
  #navbar-main {
    padding-top: calc(1rem + 30px);
  }

  .main-content-padding {
    margin-top: calc(6rem + 30px);
  }

  .sidenav-topmargin {
    margin-top: calc(79px + 30px);
  }

  .rs-drawer-left,
  .rs-drawer-right {
    .rs-drawer-content {
      padding-top: 30px;
    }
  }

  .modal-content {
    margin-top: 40px;
  }

  .navbar-search-container {
    top: 110px;
    width: 100vw;
    height: calc(100vh - 110px);
  }
}

.table-responsive {
  .react-bootstrap-table-pagination {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.rs-drawer-body {
  overflow-x: hidden;
}

.meal-checkoutrow-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  p,
  p span,
  p span em,
  p em,
  p strong,
  p span strong {
    font-size: 12px !important;
    line-height: 1.4 !important;
    font-weight: 500 !important;
    margin: 0 !important;
    text-decoration: none !important;
    font-style: normal !important;
    color: $gray-700 !important;
  }
}

.order-label {
  width: 4.03in;
  height: 5.3in;
  padding: 0in 0.07in 0in 0.07in;
  margin-right: 0.1in;
  margin-left: 0.1in;
  margin-top: 0.075in;
  margin-bottom: 0.075in;
  overflow: hidden;
  outline: 1px double;
  border: 1px solid #fff;
  display: inline-block;
}

@media print {
  .label-page-container {
    @page {
      size: 8.5in 11in portrait;
      margin: 0in;
    }
  }
}

.page-break {
  page-break-before: always;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: $superlight;
  }
  100% {
    background-color: $lighter;
  }
}

.rdrNoSelection {
  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    background: $white;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: $dark;

    &:after {
      background: lighten($primarysl, 5%);
    }
  }
}

.pln-richtext-toolbar {
  border-color: $lighter !important;

  .rdw-option-wrapper,
  .rdw-dropdown-wrapper {
    border-color: $lighter !important;
    height: 30px;
    color: $dark;

    text-align: center;
    transition: all 0.1s ease-in;

    &:hover {
      box-shadow: none;
      background-color: $lighter;
    }
  }

  .rdw-option-wrapper {
    width: 30px;
  }
}

.pln-richtext-editor {
  background: $white;
  border: 1px solid $lighter;
  margin-top: 1rem;
  color: $dark;
  border-radius: 4px;

  .DraftEditor-editorContainer {
    background: $white !important;
  }
}

.plnu-topic {
  p,
  p span {
    font-family: $font-family-base !important;
    color: $dark !important;
    font-size: 1rem !important;
    margin-bottom: 1.3rem;
  }

  ul {
    padding-left: 2.5rem;
    margin-bottom: 1.7rem;

    li {
      color: $dark !important;
      font-size: 1rem !important;
      margin-bottom: 0.5rem;
      font-family: $font-family-base !important;

      span {
        color: $dark !important;
        font-size: 1rem !important;
        margin-bottom: 0.5rem;
        font-family: $font-family-base !important;
      }
    }
  }

  h1 {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $lighter;

    font-size: 1.625rem !important;
    color: $dark !important;
    font-family: $font-family-base !important;

    span {
      font-size: 1.625rem !important;
      color: $dark !important;
      font-family: $font-family-base !important;
    }

    &:not(:first-child) {
      margin-top: 2.5rem;
    }
  }

  h2 {
    color: $dark !important;
    font-family: $font-family-base !important;
    font-size: 20px !important;

    span {
      color: $dark !important;
      font-family: $font-family-base !important;
      font-size: 20px !important;
    }

    margin-top: 1.7rem;
    margin-bottom: 0.6rem;
  }
}

.rdrDayNumber,
.rdrDayToday {
  font-weight: 500 !important;
}

.rdrInRange {
  color: lighten($primarysl, 5%) !important;
}

.rdrInRange ~ .rdrDayNumber span {
  color: $dark !important;
}

.rdrStaticRangeLabel {
  font-weight: bold;
  font-size: 14px;
  color: $dark;
}

.rdrDayInPreview,
.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrStaticRangeSelected {
  color: $primary !important;

  .rdrStaticRangeLabel {
    color: $primary;
  }
}

.rdrDayToday .rdrDayNumber span:after {
  background: $primary;
}

.rdrWeekDay {
  color: $dark;
}

.rdrDayPassive .rdrInRange {
  display: unset;
}

.rdrDayHovered {
  .rdrDayStartPreview,
  .rdrDayEndPreview {
    color: $primary !important;
  }
}

.rdrMonthName {
  display: none;
}

.rdrDateDisplayItem {
  box-shadow: none;
  border: 1px solid $lighter;

  &.rdrDateDisplayItemActive {
    color: $primary;
    border: 1px solid $primary;
  }

  input {
    color: $dark;
  }
}

.rdrStartEdge,
.rdrEndEdge {
  color: $primary !important;
}

.rdrStartEdge,
.rdrDayStartPreview,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrDayInPreview {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.rdrEndEdge,
.rdrDayEndPreview,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrDayInPreview {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rdrInputRanges {
  display: none;
}

.rdrStartEdge ~ .rdrDayNumber,
.rdrEndEdge ~ .rdrDayNumber {
  font-weight: 800;
}

.rdrDateDisplayWrapper {
  border-bottom: 1px solid $lighter;
  border-top: 1px solid $lighter;
  border-right: 1px solid $lighter;
  background: $superlight;
}

.rdrDefinedRangesWrapper {
  border-right: 1px solid $lighter;
}

.rdrStaticRange {
  border-bottom: 1px solid $lighter;
  border-left: 1px solid $lighter;
  transition: all 0.07s ease-in;

  &:hover {
    .rdrStaticRangeLabel {
      background: $superlight !important;
    }
  }

  &:first-child {
    border-top: 1px solid $lighter;
  }
}

.rdrMonthPicker,
.rdrYearPicker {
  font-size: 14px;
}

@include media-breakpoint-up(lg) {
  .reports-store-picker {
    .rs-picker {
      width: 250px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .pln-daterange-group {
    display: flex;

    .pln-daterange {
      flex: 1;
    }
  }
}

.reports-store-picker,
.reports-display-picker {
  .rs-picker-toggle {
    height: 32px;
    padding-top: 5px !important;
  }

  .rs-picker-toggle-clean {
    top: 6px !important;
  }

  .rs-picker-toggle-caret {
    top: 6px !important;
  }
}

@include media-breakpoint-down(sm) {
  .rdrDateRangePickerWrapper {
    display: block;
  }

  .rdrDefinedRangesWrapper {
    width: 100%;
  }

  .rdrStaticRanges {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
  }

  button.rdrStaticRange {
    width: 100% !important;

    .rdrStaticRangeLabel {
      text-align: center;
    }
  }
}

.rs-checkbox-checker label {
  color: $dark;
}

.sales-chart canvas {
  background-color: $white;
}

/* GRID */

.calrow {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.calrow-middle {
  align-items: center;
}

.calcol {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.calcol-start {
  justify-content: flex-start;
  text-align: left;
}

.calcol-center {
  justify-content: center;
  text-align: center;
}

.calcol-end {
  justify-content: flex-end;
  text-align: right;
}

.map-tooltip {
  position: absolute;
  margin: 8px;
  background: $white;
  color: $dark;
  max-width: 300px;
  
  z-index: 9;
  pointer-events: none;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  /*transform: scale(1.75);*/
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;

  border-bottom: 1px solid var(--border-color);

  .calcol {
    padding: 0.75em 0;
    border-right: 1px solid $lighter;
  }
}

.calendar {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $superlight;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $lighter;
    cursor: pointer;
    transition: all 0.1s ease-in;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $light;
  }
}

.calendar .calbody {
  white-space: nowrap;
  overflow-x: scroll;
}

.calendar .body .cell {
  position: relative;
  height: 4em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
  font-size: 1.5em;
  height: 430px;
  overflow-y: scroll;
  border-right: 1px solid $lighter;
  margin-bottom: -6px;
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .today {
  .number {
    padding: 0.25rem;
    border-radius: 6px;
    background: $primary;
    color: $white !important;
    top: 0.56rem !important;
    right: 0.56rem !important;
  }
}
.calendar .body .calrow {
  border-bottom: 1px solid var(--border-color);
}

/* .calendar .body .row:last-child {
  border-bottom: none;
} */

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
  color: $dark;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}

.calendar .calbody .calcol {
  min-width: 250px;
  width: calc(100% / 7);
  max-width: calc(100% / 7);
  display: inline-block;
}

.appointment-card {
  color: $dark !important;

  &.nc-consult {
    background: #fee5df;
    border: 1px solid $primary;

    &:hover {
      background: $primarysl;
    }
  }

  &.ec-consult {
    background: #dcf7eb;
    border: 1px solid $green;

    &:hover {
      background: #bbf0da;
    }
  }

  &.challenge-io {
    background: #dcf7eb;
    border: 1px solid $green;

    &:hover {
      background: #bbf0da;
    }
  }
}

@media (max-width: 767px) {
  .upcoming-appt-filter {
    max-width: calc(100% - 50px - 52px);
  }
}

h1, h2, h3, h4, h5, h6, p {
  color: $dark;
}

@media (min-width: 768px) {
  .upcoming-appt-filter {
    max-width: 300px;
  }
}

.content-card {
  color: $dark !important;

  &.social-media {
    background: #e1d7f7;
    border: 1px solid $purple;

    &:hover {
      background: #c8b7f0;
    }
  }

  &.broadcast {
    background: #fee5df;
    border: 1px solid $primary;

    &:hover {
      background: $primarysl;
    }
  }

  &.email {
    background: #dcf7eb;
    border: 1px solid $green;

    &:hover {
      background: #bbf0da;
    }
  }
}

.reallySimpleInfiniteScroll {
  overflow: auto;
}

.dropdown-popover {
  border: 1px solid $lighter;
  margin-top: 0 !important;

  .rs-dropdown-menu {
    padding-top: 0;
  }

  .rs-popover-arrow {
    display: none;
  }
}

.epr-search {
  font-size: 0.875rem !important;
  background: $white !important;
  font-family: "Karla", sans-serif !important;
  color: $dark !important;
  border-color: $lighter !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
  height: calc(1.8125rem + 2px) !important;
}

.epr-icn-search {
  top: calc(50% - 1px) !important;
  left: 6px !important;
}

.epr-btn-clear-search {
  right: 2px !important;
  top: 15px !important;
}

.epr-btn.epr-btn-clear-search {
  border-radius: 0.25rem !important;
  height: calc(100% - 3px) !important;

  &:hover {
    background-color: $primaryul !important;

    .epr-icn-clear-search {
      background-position-y: 0px !important;
    }
  }
}

.epr-main {
  border: none !important;

  height: calc(100vh - 5rem) !important;

  max-height: 450px !important;
}

.ios-padding {
  .epr-main {
    height: calc(100vh - 6.5rem) !important;

    max-height: 450px !important;
  }
}

.epr-category-nav {
  border-bottom: 1px solid $lighter;
}

.EmojiPickerReact button.epr-emoji:hover > *,
.EmojiPickerReact button.epr-emoji:focus > * {
  background-color: lighten($primarysl, 7%) !important;
}

.epr-emoji-category-label,
.epr-preview-emoji-label {
  font-family: "Karla", sans-serif !important;
  color: $dark !important;
}

.rs-drawer-open {
  .pac-container {
    z-index: 9999;
  }
}

.epr-header {
  .Flex.epr-header-overlay.FlexRow {
    display: none;
  }
}

.rs-input-number-noarrows {
  .rs-input-number-btn-group-vertical {
    display: none;
  }

  input {
    text-align: center;
  }
}

.tr-text-gray-500 {
  color: $dark;
}

.tr-text-orange-500 {
  color: $primary;
}

.tremor-base.list-element {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}

.recharts-text {
  font-family: $font-family-base;
}

.tremor-base.tr-shadow.tr-ring-gray-200.tr-rounded-lg {
  border: 1px solid $superlight !important;
}

.pageheader-sticky {
  top: 81px;
}

@include media-breakpoint-down(sm) {
  .pageheader-sticky {
    top: 0;

    &.notabs {
      min-height: 81px;

      .border-bottom {
        padding-top: 24px !important;
        padding-bottom: 24px !important;
      }
    }
  }

  .ios-padding {
    .pageheader-sticky {
      top: 30px;

      &.notabs {
        min-height: 81px;

        .border-bottom {
          padding-top: 24px !important;
          padding-bottom: 24px !important;
        }
      }
    }
  }
}

.ios-padding {
  .pageheader-sticky {
    top: 111px;

    &.notabs {
      min-height: 81px;

      .border-bottom {
        padding-top: 24px !important;
        padding-bottom: 24px !important;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .fixed-top.navbar {
    z-index: 1031 !important;
  }

  .fixed-left.navbar {
    z-index: 1031 !important;
  }
}

.uppy-Dashboard-browse {
  color: $primary !important;

  &:hover,
  &:focus {
    border-bottom: 1px solid $primary !important;
  }
}

.uppy-Dashboard-dropFilesHereHint {
  border: 1px dashed $primary !important;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 1v1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2V1zm0 0V0c13.254 0 24 10.746 24 24S37.254 48 24 48 0 37.254 0 24 10.746 0 24 0v1zm7.707 19.293a.999.999 0 1 1-1.414 1.414L25 16.414V34a1 1 0 1 1-2 0V16.414l-5.293 5.293a.999.999 0 1 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7z' fill='%23fb6340'/%3E%3C/svg%3E") !important;
}

.uppy-Dashboard-AddFiles-title,
.uppy-DashboardContent-back,
.uppy-DashboardContent-addMore,
.uppy-DashboardContent-title {
  font-family: $font-family-base !important;
}

.uppy-DashboardContent-back,
.uppy-DashboardContent-save,
.uppy-DashboardContent-addMore {
  color: $primary !important;
}

.uppy-DashboardContent-addMoreCaption {
  color: $primary !important;
}

.uppy-Dashboard-inner {
  background: $ultralight !important;
  border-radius: 6px !important;
  border: 1px solid $lighter !important;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: $primary !important;
  font-family: $font-family-base !important;
}

.uppy-Dashboard-Item-previewInnerWrap {
  background-color: $primarysl !important;
}

.uppy-StatusBar-actionBtn:not(.uppy-StatusBar-actionBtn--upload):not(
    .uppy-StatusBar-actionBtn--retry
  ) {
  color: $primary !important;
}

.uppy-Dashboard-Item-previewIcon {
  color: $primary !important;

  svg {
    g {
      fill: $primary !important;
    }
  }
}

.uppy-Dashboard-AddFiles {
  border: 1px dashed $lighter !important;
}

.uppy-Dashboard-poweredBy {
  display: none !important;
}

.uploader-embedded .uppy-Dashboard-inner {
  border-radius: 0 !important;
}

.uppy-Provider-authBtn {
  background-color: $white !important;
  color: $dark !important;
  border: 1px solid $lighter !important;
  box-shadow: $box-shadow-sm !important;
}

.uppy-Provider-authTitle,
.uppy-DashboardTab-name {
  font-family: $font-family-base !important;
  color: $dark !important;
}

.uppy-DashboardContent-panel {
  background-color: $ultralight !important;
}

.uppy-DashboardTab-inner {
  box-shadow: $box-shadow-sm !important;
  border: 1px solid $lighter !important;
}

.uppy-DashboardContent-bar {
  background-color: $superlight !important;
  border-bottom: 1px solid $lighter !important;
}

.uppy-ProviderBrowser-header {
  border-bottom: 1px solid $lighter !important;
}

.uppy-ProviderBrowser-headerBar {
  color: $dark !important;
  background-color: $superlight !important;
  font-family: $font-family-base !important;
}

.uppy-ProviderBrowser-user,
.uppy-Provider-breadcrumbs {
  color: $dark !important;
}

.uppy-ProviderBrowser-userLogout {
  color: $primary !important;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner span {
  font-family: $font-family-base !important;
  color: $dark !important;
}

.uppy-ProviderBrowser-filterInput {
  border: 1px solid $lighter !important;
}

.uppy-c-btn-primary:not(.uppy-Provider-authBtn) {
  background-color: $primary !important;
}

.uppy-c-btn {
  font-family: $font-family-base !important;
}

.preview-container .pg-viewer-wrapper {
  max-height: 300px;

  video {
    height: 300px;
    width: 100%;
  }
}

.rs-picker-menu {
  z-index: 9999;
}

.tippy-box {
  border: 1px solid $lighter;
  box-shadow: $box-shadow;
  background-color: $white;
  border-radius: 6px;
  max-width: 400px !important;

  .tippy-content {
    padding: 0;
    border-radius: 6px;
    overflow: hidden;
  }
}

.tippy-arrow {
  color: $lighter;
}

.note-taskitem {
  label {
    margin: 0;
  }

  input {
    //appearance: none;
    accent-color: $primary;
    cursor: pointer;
    width: 1.2em;
    height: 1.2em;
    position: relative;
    top: 5px;
    border: 2px solid $light;
    margin: 0 0.3rem 0 0;
    display: grid;
    place-content: center;
    border-radius: 3px;
  }

  p {
    margin: 0;
    padding-left: 0.5rem;
    transition: all 0.1s ease-in-out;
  }

  &[data-checked="true"] {
    p {
      text-decoration: line-through;
      color: $light;
    }
  }
}

.note-bulletlist,
.note-orderedlist {
  padding-left: 2.2rem;

  p {
    margin: 0;
  }
}

.notes-blockquote {
  border-left: 3px solid $light;
  padding-left: 1rem;
  margin-left: 0;
}

.ProseMirror .is-empty:before {
  content: attr(data-placeholder);
  float: left;
  color: $lighter;
  pointer-events: none;
  height: 0;
}

.notes-image {
  max-width: 100%;
  max-height: 550px;
}

.ProseMirror {
  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
  }

  p {
    color: $dark;
  }
}

#slash-command {
  height: auto;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

body,
html,
h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
.btn,
a {
  letter-spacing: -0.02em;
}

// Hide all elements
body.barcode-scanner-active {
  background-color: transparent !important;

  #sidenav-main,
  .main-content {
    visibility: hidden !important;
    display: none !important;
  }

  .rs-drawer-wrapper,
  .rs-drawer-backdrop {
    visibility: hidden !important;
    display: none;
  }
}

// Show only the barcode scanner modal
.barcode-scanner-modal {
  visibility: visible;
}

@media (max-width: 767px) {
  .scanner-window {
    position: fixed;
    top: 50%;
    left: 50%;
  
    transform: translate(-50%, -50%);
  
    border: 15px solid $white;
    border-radius: 50px;
    background: transparent;
    width: calc(100% - 30px);
    max-width: calc(850px * 0.76);
    height: calc(100vh - 340px);
    max-height: 850px;
    box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 768px) {
  .scanner-window {
    position: fixed;
    top: 50%;
    left: 50%;
  
    transform: translate(-50%, -50%);
  
    border: 15px solid $white;
    border-radius: 50px;
    background: transparent;
    width: calc(100% - 10px);
    max-width: calc(850px * 0.76);
    height: calc(100vh - 20px);
    max-height: 850px;
    box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.1);
  }
}

.scanner-text {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 400px;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: $dark;
  background: white;
  box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.1);
  font-size: 15px;
}

.pln-calendar-month {
  max-height: 688px;

  .rs-calendar-body {
    padding-left: 0px;
    padding-right: 0px;
  }

  .rs-calendar-header {
    padding-right: 0px;
    padding-left: 0px;
  }

  .rs-calendar-header-backward,.rs-calendar-header-forward,.rs-calendar-btn-today,.rs-calendar-header-title-date.rs-btn {
    background: transparent;
    color: $dark;
    font-family: 'Karla', sans-serif;
    border: 1px solid $dark;

    &:hover,&:focus {
      background: $dark;
      color: $white;
    }
  }

  .rs-calendar-table-cell-content:hover {
    background: $primaryul;
  }
 
  .rs-calendar-month-dropdown.show {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    height: calc(100% - 2px);
  }

  .rs-calendar-table {
    border: 1px solid $lighter;
  }
}

.university-gpt-response-container {
  color: var(--dark);
}

@media (max-width: 991px) {
  .learning-course-summary-outline-container {
    display: block;
    
    border: 1px solid $lighter;
   
  }

  .learning-course-summary-description-container {
    display: block;
  }
}

@media (min-width: 992px) {
  .learning-course-summary-outline-container {
    height: calc(100vh - 81px);
    position: fixed;
    top: 81px;
    right: 0px;
    width: 320px;
    overflow-y: auto;
  }

  .learning-course-summary-description-container {
    width: calc(100vw - 320px - 249px);
  }
}

/*body iframe {
  display: none !important;
}*/
