// stylelint-disable declaration-no-important

// Typography

@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color !important;
  }
  a#{$parent} {
    @include hover-focus {
      color: darken($color, 10%) !important;
    }
  }

  #{parent}--hover {
    @include hover-focus {
      color: $color !important;
    }
  }
}

@mixin text-emphasis-hover-variant($parent, $color) {
  #{$parent} {
    transition: color 0.1s ease-in-out;

    @include hover-focus {
      color: $color !important;
    }
  }
  a#{$parent} {
    @include hover-focus {
      color: darken($color, 10%) !important;
    }
  }
}
