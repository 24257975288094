.conversations-container {
  height: calc(100vh);
  padding-top: 81px;
  overflow: hidden;
}

.ios-padding {
  .conversations-container {
    height: calc(100vh);
    padding-top: calc(81px + 30px);
  }
}

.conversation-content-header {
  height: 74px;
}

.conversation-content-actions {
  min-height: 106px;
  position: absolute;
  bottom: 0;
}

.conversation-view-new-messages-button-container {
  position: fixed;
  bottom: 140px;
  z-index: 1;
  display: inline-block;
  transform: translateX(-50%);
}

.new-conversation-to {
  .input-group-text {
    color: $dark;
    font-size: 14px;
    font-weight: 500;
  }
}

@include media-breakpoint-up(md) {
  .conversations-container {
    display: flex;

    .conversation-list {
      min-width: 300px;
      max-width: 300px;
      width: 300px;
      height: 100%;
      overflow: hidden;

      border-right: 1px solid $gray-200;
      padding: 0;
      background: #fff;
    }

    .conversation-content {
      flex-grow: 1;
      height: 100%;
    }
  }
}

.conversation-content-actions {
  width: 100%;
}

@include media-breakpoint-down(sm) {
  .new-conversation-search-popover {
    width: calc(100% - 140px) !important;

    .rs-popover-arrow {
      display: none;
    }
  }

  .conversations-container {
    display: block;
    width: 100vw;

    .conversation-list {
      width: 100%;
      height: 100%;
      overflow: hidden;

      border-right: 1px solid $gray-200;
      padding: 0;
      background: #fff;
    }

    .conversation-content {
      width: 100%;
      height: 100%;
    }
  }

  .conversation-view-new-messages-button-container {
    left: 50%;
  }
}

@include media-breakpoint-between(sm, md) {
}

@include media-breakpoint-between(md, lg) {
  .conversation-view-new-messages-button-container {
    left: calc(50% + 150px);
  }

  .new-conversation-search-popover {
    width: calc(100% - 100px - 300px + 14px) !important;

    .rs-popover-arrow {
      display: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .conversation-view-new-messages-button-container {
    left: calc(50% + 150px + 125px);
  }

  .new-conversation-search-popover {
    width: calc(100% - 650px + 14px) !important;

    .rs-popover-arrow {
      display: none;
    }
  }
}

.new-conversation-search-popover {
  margin-top: 0 !important;
  border: 1px solid $lighter;
}

.conversation-content-header.drawer-mode {
  padding-left: 55px !important;
}

.conversation-content {
  height: 100%;
  overflow: hidden;
  position: relative;

  .conversation-content-header {
    background: $white;

    &.drawer-mode {
      padding-left: 55px;
    }
  }

  .conversation-content-messages {
    height: calc(100% - 179px);
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

.conversation-message {
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid $lighter;
  background: $white;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 95%;
  max-width: 550px;

  &.sent {
    border-color: lighten($primary, 18%);
    background: $primaryul;
    margin-left: auto;

    &.note {
      background-color: lighten($yellow, 46%);
      border: 1px dashed lighten($yellow, 0%);
    }
  }
}

.conversation-list-item-container {
  height: calc(100% - 139px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.conversation-list-filter-item {
  flex-grow: 1;
  border-bottom: 3px solid $superlight;
  transition: all 0.1s ease-in;
  color: $text-muted;
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &.active {
    border-color: $primary;
    color: $dark;
  }

  &:hover {
    border-color: $primary;
    color: $dark;
  }
}

.conversation-list-item {
  background-color: $white;
  font-weight: normal;

  &:hover {
    background-color: #f6f9fc !important;
    transform: none !important;
    box-shadow: none !important;
  }

  .action-dropdown-button {
    display: none;
    position: absolute;
    right: 1rem;
    bottom: 1rem;

    button {
      height: 1.5rem;
      width: 1.5rem;

      i {
        font-size: 19px !important;
      }
    }
  }

  &:hover {
    .action-dropdown-button {
      display: block;
    }

    .unread-icon {
      visibility: hidden;
    }

    .list-item-body {
      max-width: calc(100% - 32px);
    }
  }

  .list-item-body.short {
    max-width: calc(100% - 32px);
  }
}

.conversation-drawer {
  .rs-drawer-body-close {
    z-index: 9;
    top: 22px;
  }
}

.message-pane-inner {
  height: 100%;
  width: 100%;
  position: relative;
}

.ios-padding {
  .conversations-container {
    .message-pane-inner {
      transition: height 0.1s ease-in-out;
    }
  }

  .conversation-content-actions {
    padding-bottom: 1.5rem;
    //transition: padding-bottom 0.1s ease-in;
    //position: fixed;
  }

  .conversation-content-messages {
    height: calc(100% - 179px - 1.5rem);
    transition: height 0.1s ease-in;
  }

  &.keyboard-open {
    .conversation-content-actions {
      padding-bottom: 0;
    }

    .conversation-content-messages {
      height: calc(100% - 179px);
    }
  }
}

.message-body div span {
  display: inline-block;
}

/*@include media-breakpoint-between(sm, md) {
  .ios-padding {
    .conversation-content-actions {
      width: calc(100% - 300px) !important;
    }
  }
}

@include media-breakpoint-up(md) {
  .ios-padding {
    .conversation-content-actions {
      width: calc(100% - 250px - 300px);
    }
  }
}

@include media-breakpoint-between(xs, sm) {
  .ios-padding {
    .conversation-content-actions {
      width: 100% !important;
    }
  }
}*/
